import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Text from '../../../Text';
const TableFiltersDateRange = () => {
    return (_jsxs(Stack, { direction: "column", gap: 4, padding: 2, children: [_jsxs(Box, { children: [_jsx(Text, { variant: "body", children: "From:" }), _jsx(DatePicker
                    // // onError={newError => setErrorDate(newError)}
                    , { 
                        // // onError={newError => setErrorDate(newError)}
                        format: "MM-DD-YYYY", label: "mm/dd/yyyy" })] }), _jsxs(Box, { children: [_jsx(Text, { variant: "body", children: "To:" }), _jsx(DatePicker, { format: "MM-DD-YYYY", label: "mm/dd/yyyy" })] })] }));
};
export default TableFiltersDateRange;
