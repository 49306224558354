import dtFetch from '@utils/dtFetch';
import FullDataReportFiltersResponse from '../../types/privacyPlease/FullDataReportFilters';
const getPrivacyPleaseFullDataReportFilters = async () => {
    const baseUrl = ':sevenhell/v2/privacy_please/full_data_report/filters';
    const response = await dtFetch({
        schema: FullDataReportFiltersResponse,
        url: baseUrl,
    });
    return response;
};
export default getPrivacyPleaseFullDataReportFilters;
